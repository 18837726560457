var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
const contract = namespace('contract');
const defaultIllustration = 'documents/checklist';
const defaultInlineLayout = false;
const defaultParagraphs = [
    ['mail', 'mail'],
    ['expert', 'person'],
    ['caseNumber'],
];
let NoSuitableExpertiseSchedule = class NoSuitableExpertiseSchedule extends Vue {
    get noSuitableConfig() {
        var _a;
        return (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.expertNoSuitable;
    }
    get illustration() {
        var _a, _b;
        return (_b = (_a = this.noSuitableConfig) === null || _a === void 0 ? void 0 : _a.illustration) !== null && _b !== void 0 ? _b : defaultIllustration;
    }
    get paragraphs() {
        var _a, _b;
        return (_b = (_a = this.noSuitableConfig) === null || _a === void 0 ? void 0 : _a.paragraphs) !== null && _b !== void 0 ? _b : defaultParagraphs;
    }
    get inlineLayout() {
        var _a, _b;
        return (_b = (_a = this.noSuitableConfig) === null || _a === void 0 ? void 0 : _a.inline) !== null && _b !== void 0 ? _b : defaultInlineLayout;
    }
    getExpertDescription() {
        var _a, _b, _c, _d, _e;
        const noApi = this.claim.noSuitableExpertTimeSlotReason === 'no_appointment_api' ||
            (((_b = (_a = this.contract) === null || _a === void 0 ? void 0 : _a.specialConditions) === null || _b === void 0 ? void 0 : _b.country) &&
                ((_d = (_c = this.contract) === null || _c === void 0 ? void 0 : _c.specialConditions) === null || _d === void 0 ? void 0 : _d.country) !== 'FR');
        return !noApi
            ? this.$tc('compensation.claim.no_suitable_expertise_schedule.description', 0, {
                nomExpert: ((_e = this.claim.expert) === null || _e === void 0 ? void 0 : _e.name) || this.$tc('compensation.claim.no_expert'),
            })
            : this.$tc('compensation.claim.no_suitable_expertise_schedule.description.no_api');
    }
    get contents() {
        return this.paragraphs.map(([key, icon]) => ({
            icon,
            html: key === 'expert'
                ? this.getExpertDescription()
                : this.$tc(`compensation.claim.no_suitable_expertise_schedule.description.${key}`, undefined, this.noSuitableInfo),
        }));
    }
    get noSuitableInfo() {
        var _a;
        return {
            expertCompanyName: ((_a = this.claim.expert) === null || _a === void 0 ? void 0 : _a.name) || this.$tc('compensation.claim.no_expert'),
            caseNumber: this.claim.caseNumber,
        };
    }
};
__decorate([
    claim.State('claim')
], NoSuitableExpertiseSchedule.prototype, "claim", void 0);
__decorate([
    contract.State('selectedContract')
], NoSuitableExpertiseSchedule.prototype, "contract", void 0);
NoSuitableExpertiseSchedule = __decorate([
    Component({
        components: {
            ParagraphsWithIcon,
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], NoSuitableExpertiseSchedule);
export default NoSuitableExpertiseSchedule;
