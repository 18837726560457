import { render, staticRenderFns } from "./DoorLockType.vue?vue&type=template&id=6f2e0090&scoped=true"
import script from "./DoorLockType.vue?vue&type=script&lang=ts"
export * from "./DoorLockType.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f2e0090",
  null
  
)

export default component.exports