var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { DeclarationPage } from '@declaration/pages';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { appointmentEndTime, appointmentLongDateDay, appointmentStartTime, } from '@declaration/services/appointmentLabels';
import { ClaimCompensationOption, ClaimTypeTrigram, CompensationMode, } from '@shared/types/api/claim';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { hasFeature } from '@shared/featureFlags/featureFlags';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
const counting = namespace('counting');
const qualification = namespace('qualification');
const pages = namespace('pages');
const defaultConfirmContentsOrder = [];
const defaultInlineMode = false;
let CompensationConfirmation = class CompensationConfirmation extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/checklist.svg');
        this.confirmationsParagraphs = [];
    }
    get appointmentConfig() {
        var _a;
        return ((_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.indemnisationConfirmContents) || null;
    }
    get isInlineMode() {
        var _a, _b;
        return (_b = (_a = this.appointmentConfig) === null || _a === void 0 ? void 0 : _a.inline) !== null && _b !== void 0 ? _b : defaultInlineMode;
    }
    get confirmContentsOrder() {
        var _a, _b;
        return (_b = (_a = this.appointmentConfig) === null || _a === void 0 ? void 0 : _a.paragraphs) !== null && _b !== void 0 ? _b : defaultConfirmContentsOrder;
    }
    get artisanPartnerAppointment() {
        return this.claim.artisanAppointment;
    }
    get contents() {
        this.confirmationsParagraphs.push({
            key: 'descriptionMail',
            icon: 'mail',
            html: this.$tc('compensation.confirmation.description.mail'),
        });
        const showIndemnisationMessageForREN = this.hasOnlyREN(this.counting.breakInDamages) &&
            this.hasOnlyREN(this.counting.roomDamages) &&
            this.hasOnlyREN(this.counting.externalDamages) &&
            this.counting.personalProperties.length > 0;
        if (showIndemnisationMessageForREN &&
            !insurerHasFeature(InsurerFeatureFlag.NeverShowIndemnisationMessage)) {
            if (this.counting.breakInDamages.length === 0 &&
                this.typeTrigram === ClaimTypeTrigram.Robbery) {
                this.confirmationsParagraphs.push({
                    key: 'robberyEuro',
                    icon: 'euro',
                    html: `${this.$tc('compensation.confirmation.description.euro')}${this.claim.isPendingRepairQuotePending
                        ? `<br/><br/>${this.$tc('compensation.confirmation.description.proof_needed')}`
                        : ''}`,
                });
            }
            else if (this.counting.personalProperties.length >= 1) {
                this.confirmationsParagraphs.push({
                    key: 'personalPropertiesEuro',
                    icon: 'euro',
                    html: `${this.$tc('compensation.confirmation.description.euro')}${this.claim.isPendingRepairQuotePending
                        ? `<br/><br/>${this.$tc('compensation.confirmation.description.proof_needed')}`
                        : ''}`,
                });
            }
        }
        if (this.counting.breakInDamages.filter((damage) => damage.damageSeverity === 'slight').length >
            0 &&
            this.typeTrigram === ClaimTypeTrigram.Robbery &&
            this.counting.personalProperties.length === 0) {
            this.confirmationsParagraphs.push({
                key: 'robberySlightSeverityEuro',
                icon: 'euro',
                html: `${this.$tc('compensation.confirmation.description.euro')}${this.claim.isPendingRepairQuotePending
                    ? `<br/><br/>${this.$tc('compensation.confirmation.description.proof_needed')}`
                    : ''}`,
            });
        }
        if (this.claim.chosenCompensationOption === ClaimCompensationOption.PartnerArtisan &&
            this.artisanPartnerAppointment &&
            this.counting.breakInDamages.filter((damage) => damage.damageSeverity === 'slight').length ===
                0) {
            this.confirmationsParagraphs.push({
                key: 'artisanPartnerAppointmentInfo',
                icon: 'calendar',
                html: this.$tc('compensation.appointment.info', 0, {
                    date: appointmentLongDateDay(this.artisanPartnerAppointment, this.$tc.bind(this)),
                    startTime: appointmentStartTime(this.artisanPartnerAppointment),
                    endTime: appointmentEndTime(this.artisanPartnerAppointment),
                    companyName: this.artisanPartnerAppointment.artisanCompanyName,
                }),
            });
        }
        else if (this.hasCompensationMode(this.counting.breakInDamages, CompensationMode.CompensationModeCertifiedArtisan) ||
            this.hasCompensationMode(this.counting.roomDamages, CompensationMode.CompensationModeCertifiedArtisan) ||
            this.hasCompensationMode(this.counting.externalDamages, CompensationMode.CompensationModeCertifiedArtisan)) {
            // Artisan certifié pour travaux restants
            if (this.counting.isCertifiedArtisanOptionAvailable &&
                this.counting.breakInDamages.filter((damage) => damage.damageSeverity === 'slight')
                    .length === 0) {
                this.confirmationsParagraphs.push({
                    key: 'certifiedArtisanAppointmentInfo',
                    icon: 'calendar',
                    html: this.$tc('compensation.appointment.info_no_date'),
                });
            }
        }
        else if (this.hasCompensationMode(this.counting.breakInDamages, CompensationMode.CompensationModePecuniary) ||
            this.hasCompensationMode(this.counting.roomDamages, CompensationMode.CompensationModePecuniary) ||
            this.hasCompensationMode(this.counting.externalDamages, CompensationMode.CompensationModePecuniary)) {
            // Artisan du choix de l'utilisateur
            this.confirmationsParagraphs.push({
                key: 'otherArtisanAppointmentInfo',
                icon: 'euro',
                html: this.$tc('compensation.selection.other_artisan.description'),
            });
        }
        else if (!showIndemnisationMessageForREN &&
            !insurerHasFeature(InsurerFeatureFlag.NeverShowIndemnisationMessage)) {
            // Auto réparation + autres cas
            this.confirmationsParagraphs.push({
                key: 'defaultArtisanInfo',
                icon: 'euro',
                html: `${this.$tc('compensation.confirmation.description.euro')}${this.claim.isPendingRepairQuotePending
                    ? `<br/><br/>${this.$tc('compensation.confirmation.description.proof_needed')}`
                    : ''}`,
            });
        }
        this.confirmationsParagraphs.push({
            key: 'caseSummaryInfo',
            icon: '',
            html: `${this.$tc('claim.case_number.summary', 0, { caseNumber: this.claim.caseNumber })}`,
        });
        const orderedContents = [];
        if (this.confirmContentsOrder.length) {
            // let's use the config to reorder the confirmations paragraphs
            this.confirmationsParagraphs.sort((a, b) => {
                return (this.confirmContentsOrder.findIndex((elt) => elt.key === a.key) -
                    this.confirmContentsOrder.findIndex((elt) => elt.key === b.key));
            });
        }
        for (const elt of this.confirmationsParagraphs) {
            const customElement = this.confirmContentsOrder.find((item) => item.key === elt.key);
            orderedContents.push({ icon: (customElement === null || customElement === void 0 ? void 0 : customElement.icon) || elt.icon, html: elt.html });
        }
        return orderedContents;
    }
    get showUploadInvoice() {
        return (this.damagesRefund.appliedAmounts.delayed &&
            hasFeature(InsurerFeatureFlag.DisplayUploadInvoiceForConfirmationStep, this.typeTrigram));
    }
    uploadInvoice() {
        this.overridePage(DeclarationPage.CompensationOtherArtisanSendInvoice);
    }
    hasOnlyREN(damages) {
        if (!damages) {
            return false;
        }
        return (damages.filter((damage) => damage.compensationMode !== CompensationMode.CompensationModeCertifiedArtisan &&
            damage.compensationMode !== CompensationMode.CompensationModeNone &&
            damage.pendingCompensationMode !== CompensationMode.CompensationModeCertifiedArtisan &&
            damage.pendingCompensationMode !== CompensationMode.CompensationModeNone).length === 0);
    }
    hasCompensationMode(damages, compensationMode) {
        if (!damages || damages.length === 0) {
            return false;
        }
        return (damages.filter((damage) => damage.compensationMode === compensationMode ||
            damage.pendingCompensationMode === compensationMode).length > 0 || damages.filter((damage) => damage.damageSeverity === 'slight').length > 0);
    }
};
__decorate([
    counting.State('counting')
], CompensationConfirmation.prototype, "counting", void 0);
__decorate([
    qualification.State('typeTrigram')
], CompensationConfirmation.prototype, "typeTrigram", void 0);
__decorate([
    counting.Getter('damagesRefund')
], CompensationConfirmation.prototype, "damagesRefund", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], CompensationConfirmation.prototype, "updateAndSaveClaimStep", void 0);
__decorate([
    claim.State('claim')
], CompensationConfirmation.prototype, "claim", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], CompensationConfirmation.prototype, "isUpdatingClaim", void 0);
__decorate([
    pages.Action('overridePage')
], CompensationConfirmation.prototype, "overridePage", void 0);
CompensationConfirmation = __decorate([
    Component({
        components: {
            ParagraphsWithIcon,
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            goBack: null,
        },
    })
], CompensationConfirmation);
export default CompensationConfirmation;
