var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import ArtisanAppointmentDateSelection from '@declaration/components/appointment/ArtisanAppointmentDateSelection.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { ArtisanAppointmentLabel } from '@shared/types/api/claim';
const artisanAppointment = namespace('artisanAppointment');
const claim = namespace('claim');
let ArtisanLeakageSearchAppointmentDateSelection = class ArtisanLeakageSearchAppointmentDateSelection extends Vue {
    constructor() {
        super(...arguments);
        this.onConfirmCallback = async ({ endDateTime, startDateTime, artisanExtranetLink, artisanName, userContact, }) => {
            try {
                await this.confirmAppointment({
                    endDateTime,
                    startDateTime,
                    extranetLink: artisanExtranetLink,
                    companyName: artisanName,
                    label: ArtisanAppointmentLabel.LeakageCauseSearch,
                    userContact,
                });
                await this.updateAndSaveClaim({
                    step: Step.ArtisanLeakageCauseSearchAppointmentChoice,
                    claimPayload: {},
                });
            }
            catch (err) {
                this.$buefy.toast.open({
                    message: 'Une erreur est survenue, merci de réessayer.',
                    type: 'is-danger',
                    position: 'is-bottom',
                });
            }
        };
    }
};
__decorate([
    artisanAppointment.Action('confirmAppointment')
], ArtisanLeakageSearchAppointmentDateSelection.prototype, "confirmAppointment", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], ArtisanLeakageSearchAppointmentDateSelection.prototype, "updateAndSaveClaim", void 0);
ArtisanLeakageSearchAppointmentDateSelection = __decorate([
    Component({
        meta: {
            close: quitClaimDeclarationCloseCallback,
            title: 'appointment.artisan.date_selection.breadcrumb_title',
            color: CURRENT_CLAIM_TYPE_LIGHT_LAYOUT_COLOR,
        },
        components: { ArtisanAppointmentDateSelection },
    })
], ArtisanLeakageSearchAppointmentDateSelection);
export default ArtisanLeakageSearchAppointmentDateSelection;
