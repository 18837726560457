import { render, staticRenderFns } from "./dommages-immobiliers-assurance-proprietaire.vue?vue&type=template&id=2c07da3c&scoped=true"
import script from "./dommages-immobiliers-assurance-proprietaire.vue?vue&type=script&lang=ts"
export * from "./dommages-immobiliers-assurance-proprietaire.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c07da3c",
  null
  
)

export default component.exports