import { render, staticRenderFns } from "./attente-reprise-gestion.vue?vue&type=template&id=51d96083&v-if=waitingForManagementWording2"
import script from "./attente-reprise-gestion.vue?vue&type=script&lang=ts"
export * from "./attente-reprise-gestion.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports