var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Component from 'vue-class-component';
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { breakInDamageTitle } from '@shared/breakInDamage/breakInDamageFields';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY } from '@declaration/constants/personalPropertyConstants';
import ImmediateCompensationDetailCard from '@declaration/components/Compensation/ImmediateCompensationDetailCard.vue';
import ClaimsButton from '@declaration/components/UI/ClaimsButton.vue';
import { createEmptyRefundDetail } from '@shared/types/api/refundDetail';
import { Step } from '@shared/claim/steps';
import { PersonalPropertyReceiptType } from '@shared/types/api/personalProperty';
import { fileCollectionFilesCount } from '@shared/files/uploadedFiles';
const claim = namespace('claim');
const counting = namespace('counting');
const layout = namespace('layout');
const breakInDamage = namespace('breakInDamage');
let CompensationDamagesSumUp = class CompensationDamagesSumUp extends Vue {
    constructor() {
        super(...arguments);
        this.personalPropertyImage = require('@declaration/assets/images/compensation/objets-voles.svg');
        this.breakInImage = require('@declaration/assets/images/compensation/reparation-di.svg');
        this.isLoading = false;
        this.PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY = PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY;
    }
    get hasPersonalProperties() {
        return this.counting.personalProperties.length > 0;
    }
    personalPropertyTitle(personalProperty) {
        return personalProperty.thirdPartyProductInfo
            ? personalProperty.thirdPartyProductInfo.name
            : personalProperty.designation;
    }
    breakInDamageTitle(breakInDamage) {
        return breakInDamageTitle(breakInDamage, this.$tc.bind(this));
    }
    personalPropertyProofDetails(personalProperty) {
        var _a, _b, _c;
        if ((_a = personalProperty.receipt) === null || _a === void 0 ? void 0 : _a.type) {
            return personalProperty.receipt.type === PersonalPropertyReceiptType.Pictures
                ? `${fileCollectionFilesCount(personalProperty.pictureCollection)} ${this.$tc(PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY[(_b = personalProperty.receipt) === null || _b === void 0 ? void 0 : _b.type], fileCollectionFilesCount(personalProperty.pictureCollection))}`
                : `${fileCollectionFilesCount(personalProperty.receipt.fileCollection)} ${this.$tc(PERSONAL_PROPERTY_RECEIPT_TYPES_SUMUP_TRAD_KEY[(_c = personalProperty.receipt) === null || _c === void 0 ? void 0 : _c.type], fileCollectionFilesCount(personalProperty.pictureCollection))}`;
        }
    }
    breakInDamageIndemnisationReason() {
        return null;
    }
    emptyClaimTitle() {
        return this.$tc('compensation.proposition.immediate_compensation.card.empty-claim.title');
    }
    emptyClaimReason() {
        return this.$tc('compensation.proposition.immediate_compensation.card.empty-claim.reason');
    }
    emptyRefundDetail() {
        return createEmptyRefundDetail();
    }
    async validate() {
        this.isLoading = true;
        await this.updateAndSaveClaim({
            claimPayload: {
                isCompensationOptionAccepted: true,
            },
            step: Step.CompensationDamagesSumUp,
        });
        this.isLoading = false;
    }
};
__decorate([
    breakInDamage.State('damages')
], CompensationDamagesSumUp.prototype, "damages", void 0);
__decorate([
    layout.State('color')
], CompensationDamagesSumUp.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], CompensationDamagesSumUp.prototype, "isMobile", void 0);
__decorate([
    layout.Getter('isDesktop')
], CompensationDamagesSumUp.prototype, "isDesktop", void 0);
__decorate([
    counting.State('counting')
], CompensationDamagesSumUp.prototype, "counting", void 0);
__decorate([
    counting.Getter('personalPropertiesRefund')
], CompensationDamagesSumUp.prototype, "personalPropertiesRefund", void 0);
__decorate([
    counting.Getter('breakInDamagesRefund')
], CompensationDamagesSumUp.prototype, "breakInDamagesRefund", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], CompensationDamagesSumUp.prototype, "updateAndSaveClaim", void 0);
CompensationDamagesSumUp = __decorate([
    Component({
        components: {
            ImmediateCompensationDetailCard,
            ClaimsButton,
        },
        meta: {
            close: quitClaimDeclarationCloseCallback,
            title: 'page.title.compensation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            goBack: null,
        },
    })
], CompensationDamagesSumUp);
export default CompensationDamagesSumUp;
