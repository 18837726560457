var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { ClaimTypeTrigram, RefusedRuleDetailType } from '@shared/types/api/claim';
const claim = namespace('claim');
const qualification = namespace('qualification');
const breakInDamage = namespace('breakInDamage');
const personalProperty = namespace('personalProperty');
const roomDamage = namespace('roomDamage');
const electricalDamage = namespace('electricalDamage');
const externalDamage = namespace('externalDamage');
const glassDamage = namespace('glassDamage');
const equipment = namespace('equipment');
let ClaimRefused = class ClaimRefused extends Vue {
    get illustration() {
        return require('@declaration/assets/images/documents/refus-total.svg');
    }
    get title() {
        return this.$tc('claim_refused.title');
    }
    get displayCaseNumber() {
        return this.typeTrigram !== ClaimTypeTrigram.Electric;
    }
    get hasNoDamages() {
        return (this.roomDamages.length <= 0 &&
            this.personalProperties.length <= 0 &&
            this.externalDamages.length <= 0 &&
            this.electricalDamages.length <= 0 &&
            this.breakInDamages.length <= 0 &&
            this.glassDamages.length <= 0 &&
            this.equipments.length <= 0);
    }
    get contents() {
        if (this.hasNoDamages && this.typeTrigram === ClaimTypeTrigram.Electric) {
            return [this.$tc('compensation.not_covered.no_damages.description')];
        }
        else {
            const paragraphs = this.claim.warnings
                .filter((warning) => {
                return warning.context.refusedRuleDetailType === RefusedRuleDetailType.TOTAL;
            })
                .map(({ message, context }) => {
                return this.$te(message) ? this.$tc(message, undefined, context.tradKeyData) : message;
            });
            return paragraphs;
        }
    }
};
__decorate([
    claim.State('claim')
], ClaimRefused.prototype, "claim", void 0);
__decorate([
    qualification.Getter('typeTrigram')
], ClaimRefused.prototype, "typeTrigram", void 0);
__decorate([
    breakInDamage.State('damages')
], ClaimRefused.prototype, "breakInDamages", void 0);
__decorate([
    personalProperty.State('damages')
], ClaimRefused.prototype, "personalProperties", void 0);
__decorate([
    roomDamage.State('damages')
], ClaimRefused.prototype, "roomDamages", void 0);
__decorate([
    electricalDamage.State('damages')
], ClaimRefused.prototype, "electricalDamages", void 0);
__decorate([
    externalDamage.State('damages')
], ClaimRefused.prototype, "externalDamages", void 0);
__decorate([
    glassDamage.State('damages')
], ClaimRefused.prototype, "glassDamages", void 0);
__decorate([
    equipment.State('damages')
], ClaimRefused.prototype, "equipments", void 0);
ClaimRefused = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], ClaimRefused);
export default ClaimRefused;
