var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
let DeclarationDeadlineOver = class DeclarationDeadlineOver extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/refus.svg');
    }
};
DeclarationDeadlineOver = __decorate([
    Component({
        name: 'DeclarationDeadlineOver',
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.compensation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            goBack: async (app) => {
                try {
                    await app.$store.dispatch('claim/updateAndSaveClaim', {
                        // do not send step not to be redirected but only to update status
                        claimPayload: { damageDate: null },
                    });
                    app.$router.back();
                }
                catch (_a) { }
            },
        },
    })
], DeclarationDeadlineOver);
export default DeclarationDeadlineOver;
