var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import ParagraphsWithIcon from '@declaration/components/ParagraphsWithIcon.vue';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import UserContactForm from '@declaration/components/appointment/UserContactForm.vue';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
const contract = namespace('contract');
let CallbackConfirmation = class CallbackConfirmation extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/appointment/advisorCall.svg');
        this.isUpdatingClaim = false;
    }
    get contents() {
        var _a, _b;
        return [
            {
                html: this.$tc('appointment.phone.confirmation.description', undefined, {
                    phoneNumber: (_b = (_a = INSURER_CONFIG.declaration.advisorPhoneNumbers) === null || _a === void 0 ? void 0 : _a.france) !== null && _b !== void 0 ? _b : '01 XX XX XX XX',
                }),
            },
            {
                icon: 'mail',
                html: this.$tc('compensation.confirmation.description.mail'),
            },
        ];
    }
    created() {
        this.initializeContact();
    }
    initializeContact() {
        var _a;
        if (!this.selectedContract) {
            return;
        }
        if (!this.userContact.email || !this.userContact.email.length) {
            this.updateUserContact({ email: this.selectedContract.contact.email });
        }
        if (!this.userContact.phoneNumber || !this.userContact.phoneNumber.length) {
            this.updateUserContact({
                phoneNumber: (_a = this.selectedContract.contact.mobilePhone) !== null && _a !== void 0 ? _a : this.selectedContract.contact.landlinePhone,
            });
        }
    }
};
__decorate([
    claim.Getter('userContact')
], CallbackConfirmation.prototype, "userContact", void 0);
__decorate([
    claim.Action('updateUserContact')
], CallbackConfirmation.prototype, "updateUserContact", void 0);
__decorate([
    contract.State('selectedContract')
], CallbackConfirmation.prototype, "selectedContract", void 0);
CallbackConfirmation = __decorate([
    Component({
        components: {
            ParagraphsWithIcon,
            TitleImageDescriptionLayout,
            UserContactForm,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], CallbackConfirmation);
export default CallbackConfirmation;
