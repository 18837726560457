import { render, staticRenderFns } from "./LittleGateDamageSeverity.vue?vue&type=template&id=2e7cf24d&scoped=true"
import script from "./LittleGateDamageSeverity.vue?vue&type=script&lang=ts"
export * from "./LittleGateDamageSeverity.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e7cf24d",
  null
  
)

export default component.exports