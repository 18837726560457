var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { INSURER_CONFIG } from '@shared/insurer';
let NoAdministrativeFollowupIndex = class NoAdministrativeFollowupIndex extends Vue {
    constructor() {
        var _a, _b;
        super(...arguments);
        this.illustrationKey = (_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.noAdministrativeFollowupIndex) === null || _b === void 0 ? void 0 : _b.illustration;
        this.illustrations = {
            illustrationDenied: require('@declaration/assets/images/documents/refus.svg'),
            illustrationTotalDenied: require('@declaration/assets/images/documents/refus-total.svg'),
        };
    }
    get illustration() {
        if (this.illustrationKey !== undefined) {
            return this.illustrations[this.illustrationKey];
        }
        else {
            return this.illustrations.illustrationDenied;
        }
    }
    get continuationLabel() {
        var _a, _b;
        return ((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.noAdministrativeFollowupIndex) === null || _b === void 0 ? void 0 : _b.hideContinuation)
            ? null
            : 'quit';
    }
};
NoAdministrativeFollowupIndex = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], NoAdministrativeFollowupIndex);
export default NoAdministrativeFollowupIndex;
