var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { ClaimTypeTrigram } from '@shared/types/api/claim';
const qualification = namespace('qualification');
let NoFollowupIndex = class NoFollowupIndex extends Vue {
    constructor() {
        super(...arguments);
        this.defaultIllustration = require('@declaration/assets/images/documents/checklist.svg');
        this.robberyNoDamagesIllustration = require('@declaration/assets/images/documents/refus.svg');
        this.ddeReferredTenantInsurerIllustration = require('@declaration/assets/images/documents/refus-total.svg');
    }
    close() {
        quitClaimDeclarationCloseCallback(this);
    }
    get noFollowUpCause() {
        if (this.typeTrigram === ClaimTypeTrigram.Robbery) {
            return 'robberyNoDamages';
        }
        else if (this.typeTrigram === ClaimTypeTrigram.WaterDamage) {
            return 'ddeReferredTenantInsurer';
        }
        else
            return 'vacationRentalNotCovered';
    }
    get contents() {
        let contents;
        switch (this.noFollowUpCause) {
            case 'robberyNoDamages':
                contents = {
                    title: 'error.claim.robbery.noDamages.no-followup.title',
                    illustration: this.robberyNoDamagesIllustration,
                    description: 'error.claim.robbery.noDamages.no-followup.description',
                };
                break;
            case 'ddeReferredTenantInsurer':
                contents = {
                    title: 'error.claim.dde.referredTenantInsurer.no-followup.title',
                    illustration: this.ddeReferredTenantInsurerIllustration,
                    description: 'error.claim.dde.referredTenantInsurer.no-followup.description',
                };
                break;
            default:
                contents = {
                    title: 'error.claim.no-followup',
                    illustration: this.defaultIllustration,
                    description: 'error.claim.no-followup.garanties.location.description',
                };
        }
        return contents;
    }
};
__decorate([
    qualification.State('typeTrigram')
], NoFollowupIndex.prototype, "typeTrigram", void 0);
NoFollowupIndex = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
            goBack: null,
        },
    })
], NoFollowupIndex);
export default NoFollowupIndex;
