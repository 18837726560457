import { render, staticRenderFns } from "./besoin-de-relogement.vue?vue&type=template&id=ab667546&scoped=true"
import script from "./besoin-de-relogement.vue?vue&type=script&lang=ts"
export * from "./besoin-de-relogement.vue?vue&type=script&lang=ts"
import style0 from "./besoin-de-relogement.vue?vue&type=style&index=0&id=ab667546&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab667546",
  null
  
)

export default component.exports