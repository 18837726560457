var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
const qualification = namespace('qualification');
let ArtisanLeakageSearchAppointmentIndex = class ArtisanLeakageSearchAppointmentIndex extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/appointment/leakageDetection.svg');
    }
    async acceptAppointment() {
        await this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                isHelpToFindLeakageNeeded: true,
            },
            step: Step.ArtisanLeakageCauseSearchAppointmentIndex,
        });
    }
    async rejectAppointment() {
        await this.updateAndSaveWaterDamageQualification({
            qualificationPayload: {
                isHelpToFindLeakageNeeded: false,
            },
            step: Step.ArtisanLeakageCauseSearchAppointmentIndex,
        });
    }
};
__decorate([
    qualification.Action('updateAndSaveQualification')
], ArtisanLeakageSearchAppointmentIndex.prototype, "updateAndSaveWaterDamageQualification", void 0);
ArtisanLeakageSearchAppointmentIndex = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], ArtisanLeakageSearchAppointmentIndex);
export default ArtisanLeakageSearchAppointmentIndex;
