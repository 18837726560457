var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { ClaimQualificationTypesTrad } from '@shared/types/api/claim';
const claim = namespace('claim');
const qualification = namespace('qualification');
let NoThirdPartyDeductibleApplicationInfo = class NoThirdPartyDeductibleApplicationInfo extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/documents/pause-parcours.svg');
        this.isLoading = false;
    }
    get title() {
        var _a;
        const key = (_a = ClaimQualificationTypesTrad[this.claimTypeTrigram]) !== null && _a !== void 0 ? _a : 'default';
        const tradKey = `claim.qualification.${key}.deductible_info.title`;
        return this.$te(tradKey)
            ? this.$tc(tradKey)
            : this.$tc('claim.qualification.default.deductible_info.title');
    }
    get content() {
        var _a;
        const key = (_a = ClaimQualificationTypesTrad[this.claimTypeTrigram]) !== null && _a !== void 0 ? _a : 'default';
        const tradKey = `claim.qualification.${key}.deductible_info.content`;
        return this.$te(tradKey)
            ? this.$tc(tradKey)
            : this.$tc('claim.qualification.default.deductible_info.content');
    }
    get action() {
        var _a;
        const key = (_a = ClaimQualificationTypesTrad[this.claimTypeTrigram]) !== null && _a !== void 0 ? _a : 'default';
        const tradKey = `claim.qualification.${key}.deductible_info.continue`;
        return this.$te(tradKey)
            ? this.$tc(tradKey)
            : this.$tc('claim.qualification.default.deductible_info.continue');
    }
    nextStep() {
        this.isLoading = true;
        this.updateAndSaveClaimStep(this.currentStep);
    }
};
__decorate([
    claim.Getter('currentStep')
], NoThirdPartyDeductibleApplicationInfo.prototype, "currentStep", void 0);
__decorate([
    qualification.State('typeTrigram')
], NoThirdPartyDeductibleApplicationInfo.prototype, "claimTypeTrigram", void 0);
__decorate([
    claim.Action('updateAndSaveClaimStep')
], NoThirdPartyDeductibleApplicationInfo.prototype, "updateAndSaveClaimStep", void 0);
NoThirdPartyDeductibleApplicationInfo = __decorate([
    Component({
        components: { TitleImageDescriptionLayout },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], NoThirdPartyDeductibleApplicationInfo);
export default NoThirdPartyDeductibleApplicationInfo;
