import { render, staticRenderFns } from "./prise-en-charge-assurance-locataire.vue?vue&type=template&id=0c1dc307&scoped=true"
import script from "./prise-en-charge-assurance-locataire.vue?vue&type=script&lang=ts"
export * from "./prise-en-charge-assurance-locataire.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c1dc307",
  null
  
)

export default component.exports