var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { ClaimFileType } from '@shared/types/file';
import FileCollectionUpload from '@declaration/components/upload/FileCollectionUpload.vue';
import AmountInput from '@declaration/components/UI/AmountInput.vue';
const claim = namespace('claim');
const qualification = namespace('qualification');
const helpers = namespace('helpers');
let ShouldLocalizeLeakage = class ShouldLocalizeLeakage extends Vue {
    constructor() {
        super(...arguments);
        this.fileType = ClaimFileType.ProfessionalIntervention;
    }
    get helpNeeded() {
        return this.qualification.isHelpToFindLeakageNeeded;
    }
    get illustration() {
        return this.helpNeeded
            ? require('@declaration/assets/images/appointment/assistance.svg')
            : require('@declaration/assets/images/documents/pause-parcours.svg');
    }
    get questionTitle() {
        const key = this.helpNeeded ? 'help-needed' : 'no-help-needed';
        return this.$t(`step.qualification/attente-localisation-fuite.${key}.question-title`);
    }
    get description() {
        const key = this.helpNeeded ? 'help-needed' : 'no-help-needed';
        return this.$t(`step.qualification/attente-localisation-fuite.${key}.description`);
    }
    get buttonLabel() {
        return this.$t('step.qualification/attente-localisation-fuite.validation-label');
    }
    get uploadLabel() {
        return this.$t('step.qualification/attente-localisation-fuite.upload-button-label');
    }
    get addFileLabel() {
        return this.$t('step.qualification/attente-localisation-fuite.add-file-label');
    }
    get invoice() {
        return this.qualification.plumberInvoice;
    }
    set invoice(plumberInvoice) {
        this.updateWaterDamageQualification({ qualificationPayload: { plumberInvoice } });
    }
    get invoiceAmount() {
        return this.qualification.plumberInvoiceAmount;
    }
    set invoiceAmount(plumberInvoiceAmount) {
        this.updateWaterDamageQualification({ qualificationPayload: { plumberInvoiceAmount } });
    }
    async validate() {
        var _a;
        await this.updateAndSaveWaterDamageQualification({
            step: Step.ShouldLocalizeLeakage,
            qualificationPayload: {
                isWaterLeakageCauseLocalized: true,
                plumberInvoice: this.helpNeeded ? this.qualification.plumberInvoice : this.invoice,
                plumberInvoiceAmount: this.helpNeeded
                    ? this.qualification.plumberInvoiceAmount
                    : this.invoiceAmount,
                hasPlumberIntervened: this.helpNeeded
                    ? this.qualification.hasPlumberIntervened
                    : !!((_a = this.invoice) === null || _a === void 0 ? void 0 : _a.uploadedAppFiles.length),
            },
        });
    }
};
__decorate([
    claim.State('isUpdatingClaim')
], ShouldLocalizeLeakage.prototype, "isUpdatingClaim", void 0);
__decorate([
    qualification.State('qualification')
], ShouldLocalizeLeakage.prototype, "qualification", void 0);
__decorate([
    qualification.Action('updateQualification')
], ShouldLocalizeLeakage.prototype, "updateWaterDamageQualification", void 0);
__decorate([
    qualification.Action('updateAndSaveQualification')
], ShouldLocalizeLeakage.prototype, "updateAndSaveWaterDamageQualification", void 0);
__decorate([
    helpers.Action('show')
], ShouldLocalizeLeakage.prototype, "showHelp", void 0);
ShouldLocalizeLeakage = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
            FileCollectionUpload,
            AmountInput,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], ShouldLocalizeLeakage);
export default ShouldLocalizeLeakage;
