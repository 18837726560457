var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { WHITE_LAYOUT_COLOR, CLAIM_TYPE_IMAGES, CLAIM_TYPE_LAYOUT_COLORS, } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { AVAILABLE_CLAIM_TYPES_BY_HOUSING_TYPES } from '@declaration/claim/availableClaimTypesByHousingTypes';
import { CLAIM_TYPE_TRIGRAM_TRAD_KEYS, claimTypeInfoTradKey } from '@shared/claim/labels';
import { ApiResourceName } from '@shared/types/api/api';
import { apiResourcePath } from '@shared/utils/apiResourcePath';
function claimTypeClass(trigram) {
    return `type__item--${trigram}`;
}
function claimTypeToInfo({ id, trigram, hidden }) {
    return {
        id,
        trigram,
        hidden,
        title: CLAIM_TYPE_TRIGRAM_TRAD_KEYS[trigram],
        image: CLAIM_TYPE_IMAGES[trigram],
        class: claimTypeClass(trigram),
        info: claimTypeInfoTradKey(trigram),
        background: CLAIM_TYPE_LAYOUT_COLORS[trigram].background,
    };
}
const claim = namespace('claim');
const qualification = namespace('qualification');
const contract = namespace('contract');
let ClaimType = class ClaimType extends Vue {
    constructor() {
        super(...arguments);
        this.claimTypes = [];
        this.isLoading = true;
        this.isSavingClaimType = false;
        this.selected = null;
    }
    async created() {
        try {
            const types = await this.$axios.$get(apiResourcePath(ApiResourceName.ClaimTypes));
            this.claimTypes = types['hydra:member'].map(claimTypeToInfo).filter((type) => {
                var _a;
                const housingType = (_a = this.contract) === null || _a === void 0 ? void 0 : _a.housingType.slug;
                if (!housingType) {
                    return true;
                }
                if (type.hidden) {
                    return false;
                }
                return AVAILABLE_CLAIM_TYPES_BY_HOUSING_TYPES[housingType].has(type.trigram);
            });
        }
        catch (error) {
            await this.$store.dispatch('error/add', 'Une erreur est survenue');
        }
        this.isLoading = false;
    }
    async selectType(info) {
        const { id, trigram } = info;
        if (id === null || this.isSavingClaimType) {
            return;
        }
        this.selected = info;
        this.isSavingClaimType = true;
        try {
            await this.setClaimType({
                typeIRI: id,
                typeTrigram: trigram,
                step: Step.ClaimType,
            });
        }
        catch (error) {
            await this.$store.dispatch('error/add', 'Une erreur est survenue');
        }
        this.isSavingClaimType = false;
    }
};
__decorate([
    claim.State('claim')
], ClaimType.prototype, "claim", void 0);
__decorate([
    contract.State('selectedContract')
], ClaimType.prototype, "contract", void 0);
__decorate([
    qualification.Action('setClaimType')
], ClaimType.prototype, "setClaimType", void 0);
ClaimType = __decorate([
    Component({
        components: {},
        scrollToTop: true,
        meta: {
            color: WHITE_LAYOUT_COLOR,
        },
    })
], ClaimType);
export default ClaimType;
