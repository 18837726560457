var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Step } from '@shared/claim/steps';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import UserContactForm from '@declaration/components/appointment/UserContactForm.vue';
import { INSURER_CONFIG } from '@shared/insurer';
const claim = namespace('claim');
const contract = namespace('contract');
let CallbackConfirmationTNG = class CallbackConfirmationTNG extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/appointment/advisorCall.svg');
        this.isUpdatingClaim = false;
    }
    created() {
        this.initializeContact();
    }
    initializeContact() {
        var _a;
        if (!this.selectedContract) {
            return;
        }
        if (!this.userContact.email || !this.userContact.email.length) {
            this.updateUserContact({ email: this.selectedContract.contact.email });
        }
        if (!this.userContact.phoneNumber || !this.userContact.phoneNumber.length) {
            this.updateUserContact({
                phoneNumber: (_a = this.selectedContract.contact.mobilePhone) !== null && _a !== void 0 ? _a : this.selectedContract.contact.landlinePhone,
            });
        }
    }
    async save() {
        this.isUpdatingClaim = true;
        await this.updateAndSaveClaim({ step: Step.BeCalledConfirmationTNG, claimPayload: {} });
        this.isUpdatingClaim = false;
    }
    get description() {
        var _a, _b;
        return this.$tc('appointment.phone.confirmation.description', undefined, {
            phoneNumber: (_b = (_a = INSURER_CONFIG.declaration.advisorPhoneNumbers) === null || _a === void 0 ? void 0 : _a.france) !== null && _b !== void 0 ? _b : '01 XX XX XX XX',
        });
    }
};
__decorate([
    claim.Action('updateAndSaveClaim')
], CallbackConfirmationTNG.prototype, "updateAndSaveClaim", void 0);
__decorate([
    claim.Getter('userContact')
], CallbackConfirmationTNG.prototype, "userContact", void 0);
__decorate([
    claim.Action('updateUserContact')
], CallbackConfirmationTNG.prototype, "updateUserContact", void 0);
__decorate([
    contract.State('selectedContract')
], CallbackConfirmationTNG.prototype, "selectedContract", void 0);
CallbackConfirmationTNG = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
            UserContactForm,
        },
        meta: {
            title: 'page.title.confirmation',
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            close: quitClaimDeclarationCloseCallback,
        },
    })
], CallbackConfirmationTNG);
export default CallbackConfirmationTNG;
