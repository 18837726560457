var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import * as Sentry from '@sentry/browser';
import TitleImageDescriptionLayout from '@declaration/components/TitleImageDescriptionLayout.vue';
import { quitClaimDeclarationCloseCallback } from '@declaration/helpers/layoutMetaCallback';
import { CURRENT_CLAIM_TYPE_LAYOUT_COLOR } from '@declaration/layout';
import { Step } from '@shared/claim/steps';
import { InsurerFeatureFlag, insurerHasFeature } from '@shared/featureFlags/insurerFeatureFlags';
import { ClaimCompensationOption } from '@shared/types/api/claim';
import ContactInformationModal from '@declaration/components/declaration/pages/indemnisation/artisan-partenaire/ContactInformationModal.vue';
const layout = namespace('layout');
const claim = namespace('claim');
const artisanAppointment = namespace('artisanAppointment');
let CompensationPartnerOption = class CompensationPartnerOption extends Vue {
    constructor() {
        super(...arguments);
        this.illustration = require('@declaration/assets/images/compensation/partnerArtisan.svg');
        this.isAccepting = false;
        this.isDeclining = false;
        this.displayContactInformationModal = false;
    }
    get shouldConfirmRENContactInformation() {
        return insurerHasFeature(InsurerFeatureFlag.RENContactInformationConfirmation);
    }
    chooseOption(chosenCompensationOption) {
        this.isAccepting = true;
        this.updateAndSaveClaim({
            claimPayload: {
                chosenCompensationOption,
                isCompensationOptionAccepted: true,
            },
            step: Step.CompensationOptions,
        }).catch((err) => {
            Sentry.captureException(err);
            this.isAccepting = false;
        });
    }
    confirmContactInformation(renContactInformation) {
        if (this.displayContactInformationModal && renContactInformation) {
            this.isAccepting = true;
            this.updateAndSaveClaim({
                claimPayload: {
                    chosenCompensationOption: ClaimCompensationOption.PartnerArtisan,
                    renContactInformation,
                    isCompensationOptionAccepted: true,
                },
                step: Step.CompensationOptions,
            })
                .then(() => this.closeContactInformationModal())
                .catch((err) => {
                Sentry.captureException(err);
                this.isAccepting = false;
            });
        }
        else {
            this.displayContactInformationModal = true;
        }
    }
    closeContactInformationModal() {
        this.displayContactInformationModal = false;
    }
    decline() {
        this.isDeclining = true;
        this.updateAndSaveClaim({
            step: Step.CompensationPartnerOption,
            claimPayload: {
                isCompensationOptionAccepted: false,
            },
        });
        this.isDeclining = false;
    }
};
__decorate([
    layout.State('color')
], CompensationPartnerOption.prototype, "color", void 0);
__decorate([
    layout.State('isMobile')
], CompensationPartnerOption.prototype, "isMobile", void 0);
__decorate([
    layout.Getter('isDesktop')
], CompensationPartnerOption.prototype, "isDesktop", void 0);
__decorate([
    claim.State('isUpdatingClaim')
], CompensationPartnerOption.prototype, "isUpdatingClaim", void 0);
__decorate([
    claim.Action('updateAndSaveClaim')
], CompensationPartnerOption.prototype, "updateAndSaveClaim", void 0);
__decorate([
    artisanAppointment.State('artisanName')
], CompensationPartnerOption.prototype, "artisanName", void 0);
CompensationPartnerOption = __decorate([
    Component({
        components: {
            TitleImageDescriptionLayout,
            ContactInformationModal,
        },
        meta: {
            title: 'page.title.compensation',
            close: quitClaimDeclarationCloseCallback,
            color: CURRENT_CLAIM_TYPE_LAYOUT_COLOR,
            goBack: null,
        },
    })
], CompensationPartnerOption);
export default CompensationPartnerOption;
